import React, { useState, useEffect } from "react"
import { loadStripe } from "@stripe/stripe-js"
import { v4 as uuidv4 } from "uuid"
import { Flex, Box, Button, Title, Label } from "../components/system"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"

const Cart = () => {
  const [cart, setCart] = useState([])

  const lineItems = cart.map(item => {
    return {
      price: item.stripe,
      quantity: 1,
    }
  })

  const prices = cart.map(item => {
    return item.price
  })

  const sum = arr => {
    return arr.reduce((a, b) => {
      return a + b
    }, 0)
  }

  const items = cart.map(item => {
    return {
      _key: item.name,
      _type: "item",
      product: {
        _ref: item.id,
      },
      size: item.size,
      quantity: 1,
    }
  })

  const uuid = uuidv4()

  useEffect(() => {
    if (localStorage.getItem("products")) {
      const products = JSON.parse(localStorage.getItem("products"))
      setCart(products)
    }
  }, [])

  const remove = id => {
    let storageProducts = JSON.parse(localStorage.getItem("products"))
    let products = storageProducts.filter(product => product.id !== id)
    localStorage.setItem("products", JSON.stringify(products))
    setCart(products)
  }

  const redirectToCheckout = async e => {
    e.preventDefault()

    // create order in sanity
    const mutations = [
      {
        createOrReplace: {
          _id: uuid,
          _type: "order",
          id: uuid,
          paid: false,
          items: items,
        },
      },
    ]

    await fetch(
      `https://${process.env.GATSBY_SANITY_PROJECT_ID}.api.sanity.io/v1/data/mutate/${process.env.GATSBY_SANITY_DATASET}`,
      {
        method: "post",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${process.env.GATSBY_SANITY_TOKEN}`,
        },
        body: JSON.stringify({ mutations }),
      }
    )
      .then(response => response.json())
      .catch(error => console.error(error))
    // create order in sanity

    const stripe = await loadStripe(process.env.GATSBY_STRIPE_PUBLIC)
    const { error } = await stripe.redirectToCheckout({
      lineItems: lineItems,
      mode: "payment",
      successUrl: process.env.GATSBY_SUCCESS_URL,
      cancelUrl: process.env.GATSBY_CANCEL_URL,
      shippingAddressCollection: {
        allowedCountries: ["GB"],
      },
      clientReferenceId: uuid,
    })
    if (error) {
      console.warn("Error:", error)
    }
  }

  return (
    <Layout dark>
      <Flex flexDirection="column" pt={6}>
        <Label fontSize={1} color="faded">
          Your
        </Label>
        <Title fontSize={[6, 7]} color="primary">
          Shopping Cart
        </Title>
      </Flex>
      <Flex
        width={["100%", "70%"]}
        flexDirection="column"
        alignItems="flex-start"
        // bg="red"
        px={[3, 6]}
        pt={[3, 5]}
      >
        {cart.map((id, key) => (
          <Flex
            flexDirection={["row"]}
            justifyContent="space-between"
            key={key}
            my={3}
          >
            <Box width={150}>
              <GatsbyImage image={id.img} />
            </Box>
            <Flex ml={[3, 4]} flexDirection="column" alignItems="flex-start">
              <Label
                down
                textAlign="left"
                fontWeight="normal"
                mb={1}
                fontSize={[1, 3]}
              >
                {id.name}
              </Label>
              <Label color="faded" fontSize={1}>
                {id.brand}
              </Label>
              <Label down mt={2} fontSize={1}>
                Size: {id.size}
              </Label>
              <Label color="secondary">£ {id.price}</Label>
            </Flex>
            <Flex
              onClick={() => remove(id.id)}
              height={50}
              width={50}
              minWidth={50}
              style={{ cursor: "pointer" }}
            >
              <Label fontSize={1}>X</Label>
            </Flex>
          </Flex>
        ))}
      </Flex>

      {cart.length !== 0 && (
        <Flex
          position={["relative", "fixed"]}
          bg="white"
          right={0}
          top={[null, "40%"]}
          width={["100vw", "30vw"]}
          flexDirection="column"
          alignItems="flex-start"
          p={4}
        >
          <Flex flexDirection="column" alignItems="flex-start">
            <Label mb={3} color="secondary">
              Total
            </Label>
            <Title mb={3} fontSize={[5, 6]}>
              £{sum(prices)}.00
            </Title>
          </Flex>
          <Button onClick={e => redirectToCheckout(e)} bg="black" color="white">
            Proceed to checkout ->
          </Button>
        </Flex>
      )}
    </Layout>
  )
}

export default Cart
